<script lang="ts" context="module">
  import { createModalStore } from "./stores";

  type MessageModalData = {
    title: string;
    messageHtml: string;
  };
  export const messageModal = createModalStore<MessageModalData>();

  export function showMessage(title: string, messageHtml: string) {
    return messageModal.openModal({ title, messageHtml });
  }
</script>

<script lang="ts">
  import BasicModal from "./BasicModal.svelte";

  export let data: MessageModalData;
</script>

<BasicModal title={data.title} id="error-modal" store={messageModal}>
  {@html data.messageHtml}
  <button
    class="btn btn-primary"
    on:click={messageModal.closeModal}
    slot="footer"
    type="button"
  >
    Close
  </button>
</BasicModal>
